/* Theme */
.theme--light.v-datatable thead th.column.sortable .v-icon {
  color: rgba(0,0,0,0.38);
}
.theme--light.v-datatable thead th.column.sortable:hover {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-datatable thead th.column.sortable.active {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-datatable thead th.column.sortable.active .v-icon {
  color: rgba(0,0,0,0.87);
}
.theme--light.v-datatable .v-datatable__actions {
  background-color: #fff;
  color: rgba(0,0,0,0.54);
  border-top: 1px solid rgba(0,0,0,0.12);
}
.theme--dark.v-datatable thead th.column.sortable .v-icon {
  color: rgba(255,255,255,0.5);
}
.theme--dark.v-datatable thead th.column.sortable:hover {
  color: #fff;
}
.theme--dark.v-datatable thead th.column.sortable.active {
  color: #fff;
}
.theme--dark.v-datatable thead th.column.sortable.active .v-icon {
  color: #fff;
}
.theme--dark.v-datatable .v-datatable__actions {
  background-color: #424242;
  color: rgba(255,255,255,0.7);
  border-top: 1px solid rgba(255,255,255,0.12);
}
.v-datatable .v-input--selection-controls {
  margin: 0;
  padding: 0;
}
.v-datatable thead th.column.sortable {
  cursor: pointer;
  outline: 0;
}
.v-datatable thead th.column.sortable .v-icon {
  font-size: 16px;
  display: inline-block;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-datatable thead th.column.sortable:focus .v-icon,
.v-datatable thead th.column.sortable:hover .v-icon {
  opacity: 0.6;
}
.v-datatable thead th.column.sortable.active {
  transform: none;
}
.v-datatable thead th.column.sortable.active .v-icon {
  opacity: 1;
}
.v-datatable thead th.column.sortable.active.desc .v-icon {
  transform: rotate(-180deg);
}
/** Actions */
.v-datatable__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  flex-wrap: wrap-reverse;
}
.v-datatable__actions .v-btn {
  color: inherit;
}
.v-datatable__actions .v-btn:last-of-type {
  margin-left: 14px;
}
.v-datatable__actions__range-controls {
  display: flex;
  align-items: center;
  min-height: 48px;
}
.v-datatable__actions__pagination {
  display: block;
  text-align: center;
  margin: 0 32px 0 24px;
}
.v-datatable__actions__select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 14px;
  white-space: nowrap;
}
.v-datatable__actions__select .v-select {
  flex: 0 1 0;
  margin: 13px 0 13px 34px;
  padding: 0;
  position: initial;
}
.v-datatable__actions__select .v-select__selections {
  flex-wrap: nowrap;
}
.v-datatable__actions__select .v-select__selections .v-select__selection--comma {
  font-size: 12px;
}
.v-datatable__progress {
  height: auto !important;
}
.v-datatable__progress tr,
.v-datatable__progress td,
.v-datatable__progress th {
  height: auto !important;
}
.v-datatable__progress th {
  padding: 0 !important;
}
.v-datatable__progress th .v-progress-linear {
  margin: 0;
}
.v-datatable__expand-row {
  border: none !important;
}
.v-datatable__expand-col {
  padding: 0 !important;
  height: 0px !important;
}
.v-datatable__expand-col--expanded {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.v-datatable__expand-content {
  transition: height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-datatable__expand-content > .card {
  border-radius: 0;
  box-shadow: none;
}
/*# sourceMappingURL=node_modules/vuetify/src/stylus/components/_data-table.css.map */